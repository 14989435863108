import React from 'react'
import { useState, useEffect } from 'react';

//Images
import Bottle from '../assets/images/base-bottle.png';
import butterfly from '../assets/images/beautiful-butterfly.png'

export default function Form({setCurrentComponent, handleBackButton, setPageState, pageState, formState, setFormState}) {

    let page_num = pageState

    const nameRegex = /^[a-zA-Z\s]+$/;
    const organizationRegex = /^[a-zA-Z0-9\s-']+$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    const addressRegex = /^[a-zA-Z0-9.,\s]+$/;
    const zipRegex = /[0-9]{5}/;
    const stateRegex = /^(A[LKSZRAP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[DLNA]|K[SY]|LA|M[EHDAINSOT]|N[EVHJMYCD]|O[HKR]|P[WAR]|RI|S[CD]|T[NX]|UT|V[TIA]|W[AVIY])$/;
    const cityRegex = /^[a-zA-Z\s]+(?:[\s-][a-zA-Z\s]+)*$/;
    const chosenRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\.\?,!]+$/;

    const states = [
        { label: 'AL', value: 'AL' },
        { label: 'AK', value: 'AK' },
        { label: 'AZ', value: 'AZ' },
        { label: 'AR', value: 'AR' },
        { label: 'CA', value: 'CA' },
        { label: 'CO', value: 'CO' },
        { label: 'CT', value: 'CT' },
        { label: 'DC', value: 'DC' },
        { label: 'DE', value: 'DE' },
        { label: 'FL', value: 'FL' },
        { label: 'GA', value: 'GA' },
        { label: 'HI', value: 'HI' },
        { label: 'ID', value: 'ID' },
        { label: 'IL', value: 'IL' },
        { label: 'IN', value: 'IN' },
        { label: 'IA', value: 'IA' },
        { label: 'KS', value: 'KS' },
        { label: 'KY', value: 'KY' },
        { label: 'LA', value: 'LA' },
        { label: 'ME', value: 'ME' },
        { label: 'MD', value: 'MD' },
        { label: 'MA', value: 'MA' },
        { label: 'MI', value: 'MI' },
        { label: 'MN', value: 'MN' },
        { label: 'MS', value: 'MS' },
        { label: 'MO', value: 'MO' },
        { label: 'MT', value: 'MT' },
        { label: 'NE', value: 'NE' },
        { label: 'NV', value: 'NV' },
        { label: 'NH', value: 'NH' },
        { label: 'NJ', value: 'NJ' },
        { label: 'NM', value: 'NM' },
        { label: 'NY', value: 'NY' },
        { label: 'NC', value: 'NC' },
        { label: 'ND', value: 'ND' },
        { label: 'OH', value: 'OH' },
        { label: 'OK', value: 'OK' },
        { label: 'OR', value: 'OR' },
        { label: 'PA', value: 'PA' },
        { label: 'RI', value: 'RI' },
        { label: 'SC', value: 'SC' },
        { label: 'SD', value: 'SD' },
        { label: 'TN', value: 'TN' },
        { label: 'TX', value: 'TX' },
        { label: 'UT', value: 'UT' },
        { label: 'VT', value: 'VT' },
        { label: 'VA', value: 'VA' },
        { label: 'WA', value: 'WA' },
        { label: 'WV', value: 'WV' },
        { label: 'WI', value: 'WI' },
        { label: 'WY', value: 'WY' }
    ];

    const ages = [
        {label: "21-30", value: "21-30"},
        {label: "31-40", value: "31-40"},
        {label: "41-50", value: "41-50"},
        {label: "51-60", value: "51-60"},
        {label: "61+", value: "61+"}
    ]

    const organizationCategories = [
        {label: 'Wine/Spirits Retailer', value: 'Wine/Spirits Retailer'},
        {label: 'Bar or Restaurant', value: 'Bar or Restaurant'},
        {label: 'Non-Spirits Business', value: 'Non-Spirits Business'},
        {label: 'Spirits Society', value: 'Spirits Society'},
        {label: 'Individual', value: 'Individual'},
        {label: 'Other', value: 'Other'}
    ]

    const participated = [
        {label: 'Yes', value: 'Yes'},
        {label: 'No', value: 'No'}
    ]

    const barrelOptions = [
        {label: 'Single Barrel', value: 'Single Barrel'},
        {label: 'Micro-Blend', value: 'Micro-Blend'},
    ]

    //form interactions
    const [formError, setFormError] = useState(false);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;


        if (event.target.type === 'file') {
            //if file exists, set the file to state
            if (event.target.files[0]) {
                console.log(event.target.files[0])
                setLogoFile(event.target.files[0]);
                setFormState((prevState) => ({
                    ...prevState,
                    [name]: {
                        value: event.target.files[0].name,
                        focus: prevState[name] ? prevState[name].focus : false,
                        error: prevState[name] ? prevState[name].error : undefined,
                        file: event.target.files[0]
                    }
                }));
            }
        } else {
            setFormState((prevState) => ({
                ...prevState,
                [name]: {
                value: value,
                focus: prevState[name] ? prevState[name].focus : false,
                error: prevState[name] ? prevState[name].error : undefined
                }
            }));
            console.log({target, value, name})
        }
    }

        const handleFocus = (event) => {
            const target = event.target;
            const name = target.name;

            setFormState((prevState) => ({
                ...prevState,
                [name]: {
                value: prevState[name] ? prevState[name].value : '',
                focus: true,
                error: prevState[name] ? prevState[name].error : undefined
                }
            }));
        }

        const handleBlur = (event) => {
            const target = event.target;
            const name = target.name;
            const value = target.value;
            let error;

            if (name == 'email') {
              if(!emailRegex.test(value)){
                error = 'Invalid Email';
                setFormError(true);
              } else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if (name == 'phone') {
              if(!phoneRegex.test(value)){
                error = 'Invalid Phone Number';
                setFormError(true);
              }
              else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if (name == 'fullName') {
              if(!nameRegex.test(value)){
                error = 'Invalid Full Name';
                setFormError(true);
              }
              else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if (name == 'organizationName') {
              if(!organizationRegex.test(value)){
                error = 'Invalid Organization Name';
                setFormError(true);
              }
              else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if(name =='address1'){
                if(!addressRegex.test(value)){
                    error = 'Invalid Address';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else if(name =='state'){
                if(!stateRegex.test(value)){
                    error = 'Invalid State, format should be "WA"';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else if(name =='zip'){
                if(!zipRegex.test(value)){
                    error = 'Invalid ZIP';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else if(name =='city'){
                if(!cityRegex.test(value)){
                    error = 'Invalid character(s) in City';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else if(name =='chosen'){
                if(!chosenRegex.test(value)){
                    error = 'Invalid character(s) in CHOSEN';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else { //organizationName
                if(!organizationRegex.test(value)){
                    error = 'Invalid character(s) in Organization';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }


            if (value === '') {
              setFormState((prevState) => ({
                ...prevState,
                [name]: {
                  value: '',
                  focus: false,
                  error: undefined
                }
              }));
            } else {
              setFormState((prevState) => ({
                ...prevState,
                [name]: {
                  value: prevState[name] ? prevState[name].value : '',
                  focus: true,
                  error
                }
              }));
            }
        }

    useEffect(() => {
        let inputFields = [];
        if (pageState === 'step-1') {
            inputFields = ['fullName', 'organizationName', 'email', 'phone'];
        } else {
            inputFields = ['address1','city','state','zip', 'productSelected'];
        }
        inputFields.forEach((field, index) => {
            if (formState[field]) {
                document.querySelector(`#${field}`).value = formState[field].value;
                if (formState[field].value != '') {
                    formState[field].focus = true;
                } else {
                    formState[field].focus = false;
                }
                document.querySelector(`#${field}`).setAttribute("key", index);
            }
        });
    }, [formState]);

    const handleChange = (event) => {
        const { value } = event.target;
        const name = event.target.name;
        const selectValue = event.target.value;
        console.log({value, name});
        setFormState((prevState) => ({
          ...prevState,
          [name]: {
            value: value,
            focus: prevState[name] ? prevState[name].focus : false,
            error: prevState[name] ? prevState[name].error : undefined
          }
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log(formState);
        console.log(formError);
        try {
          if(!formError) {
            console.log('works');
            await handleMailGun(event);

            //replace '#form .right' inner html with success message
            //document.querySelector('#product-contact').innerHTML = '<h5>Message has been sent!</h5><p>We will be in touch with you shortly.</p>';
            
            //set form opacity to 0
            let form = document.getElementById('contact');
            form.style.opacity = 0;
            setPageState(7)
          }

        } catch {
          console.log('error');
        }
    }

    const handleMailGun = (event) => {
        event.preventDefault();

        // let emailfullName = formState.fullName.value;
        // let emailorganizationName = formState.organizationName.value;
        // let emailEmail = formState.email.value;
        // let emailPhone = formState.phone.value;
        // let emailAddress1 = formState.address1.value;
        // let emailCity = formState.city.value;
        // let emailState = formState.stateSelected.value;
        // let emailZip = formState.zip.value;
        // let emailAge = formState.age.value;
        // let emailParticipant = formState.participant.value;
        // let emailOrganizationCategory = formState.organizationCategory.value;
        // let emailBarrelOption = formState.barrelOption.value;
        // let emailChosen = formState.chosen.value;
        // let emailLogo = formState.logo.value;


        // //prepend with message
        // let formStateString =
        // 'You just received a new form submission: '
        // + '\n\n'
        // + 'First Name: ' + emailfullName
        // + '\n\n'
        // + 'Organization Name: ' + emailorganizationName
        // + '\n\n'
        // + 'Email: ' + emailEmail
        // + '\n\n'
        // + 'Phone: ' + emailPhone
        // + '\n\n'
        // + 'Address 1: ' + emailAddress1
        // + '\n\n'
        // + 'City: ' + emailCity
        // + '\n\n'
        // + 'State: ' + emailState
        // + '\n\n'
        // + 'Zip: ' + emailZip
        // + '\n\n'
        // + 'Age: ' + emailAge
        // + '\n\n'
        // + 'Participated Before: ' + emailParticipant
        // + '\n\n'
        // + 'Organization Category: ' + emailOrganizationCategory
        // + '\n\n'
        // + 'Barrel Option: ' + emailBarrelOption
        // + '\n\n'
        // + 'If Chosen Text: ' + emailChosen
        // + '\n\n'
        // + 'Logo: ' + emailLogo
        // + '\n\n'
        // ;

        const formData = {
            to: "jon@jonjones.me",
            from: "donotreply@mail.bluerunspirits.com",
            subject: "Contact Page Form Submission",
            //text: formStateString
        };


    //   axios.post('http://localhost:3002/api/mailgun', formData)
    //   .then(response => {
    //       console.log(response.data);
    //   }).catch(error => {
    //       console.log(error);
    //   });
    }

    useEffect(() => {
        let butterfly = document.getElementById(`butterfly-7`);
        butterfly.addEventListener("animationend", function() {
            if (butterfly) {
                butterfly.querySelector('.pulse').classList.remove("pulse");
            }   
        });
      }, []);

      const [logoFile, setLogoFile] = useState(null);

  return (
    <div id='form'>
        <div className={`form-wrapper ${(pageState == 2) ? 'more-padding' : '' }`}>
            <form id='contact'>
                <div className={`page step-1 ${(pageState == 2) ? '' : 'hide' }`}>
                    {/* Full Name */}
                    <h3>Submission Questions</h3>
                    <div className="row">
                        <div className={`form-field ${formState.fullName && formState.fullName.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.fullName && formState.fullName?.error && 'red'}`}>
                                {formState.fullName && formState.fullName?.error}
                            </label>
                            <input
                                id="fullName"
                                type="text"
                                name="fullName"
                                placeholder='Full Name'
                                value={formState.fullName ? formState.fullName.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Organization Name */}
                    <div className="row">
                        <div className={`form-field ${formState.organizationName && formState.organizationName.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.organizationName && formState.organizationName?.error && 'red'}`}>
                                {formState.organizationName && formState.organizationName?.error}
                            </label>
                            <input
                                id="organizationName"
                                type="text"
                                name="organizationName"
                                placeholder='Organization Name'
                                value={formState.organizationName ? formState.organizationName.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                            <p className='org-text'>If you are not submitting on behalf of an organization, please type "Individual"</p>
                        </div>
                    </div>
                    {/* Email */}
                    <div className="row">
                        <div className={`form-field ${formState.email && formState.email.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.email &&  formState.email?.error && 'red'}`}>
                            {formState.email &&  formState.email?.error}
                            </label>
                            <input
                                id="email"
                                type="text"
                                name="email"
                                placeholder='Email'
                                value={formState.email ? formState.email.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Phone */}
                    <div className="row">
                        <div className={`form-field ${formState.phone && formState.phone.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.phone && formState.phone?.error && 'red'}`}>
                                {formState.phone && formState.phone?.error}
                            </label>
                            <input
                                id="phone"
                                type="text"
                                name="phone"
                                placeholder='Phone'
                                value={formState.phone ? formState.phone.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Address */}
                    <div className="row">
                        <div className={`form-field ${formState.address1 && formState.address1.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.address1 && formState.address1?.error && 'red'}`}>
                                {formState.address1 && formState.address1?.error}
                            </label>
                            <input
                                id="address1"
                                type="text"
                                name="address1"
                                placeholder='Address'
                                value={formState.address1 ? formState.address1.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* City, State, Zip */}
                    <div className="row">
                        <div className={`form-field ${formState.city && formState.city.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.city && formState.city.error ? 'red' : ''}`}>
                                {formState.city && formState.city?.error}
                            </label>
                            <input
                                id="city"
                                type="text"
                                name="city"
                                placeholder='City'
                                value={formState.city ? formState.city.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className={`form-field ${formState.stateSelected && formState.stateSelected.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.stateSelected && formState.stateSelected.error ? 'red' : ''}`}>
                                {formState.stateSelected && formState.stateSelected?.error}
                            </label>
                            <select onChange={handleChange} name="stateSelected" id="stateSelected" value={formState.stateSelected && formState.stateSelected.value}>
                                <option hidden value='State'>State</option>
                                {states.map((state, index) => (
                                    <option key={index} value={state.value}>
                                        {state.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={`form-field ${formState.zip && formState.zip.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.zip && formState.zip.error ? 'red' : ''}`}>
                                {formState.zip && formState.zip?.error}
                            </label>
                            <input
                                id="zip"
                                type="text"
                                name="zip"
                                placeholder='Postal Code'
                                value={formState.zip ? formState.zip.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={`page step-2 ${(pageState != 2 && pageState != 4 && pageState != 5 && pageState != 6) ? '' : 'hide' }`}>

                    <div className={`form-field ${formState.ageSelected && formState.ageSelected.focus ? 'active' : ''}`}>
                        <label className={formState.ageSelected && formState.ageSelected?.error && 'red'}>
                            {formState.ageSelected && formState.ageSelected?.error || "How old are you?"}
                        </label>
                        <select value={formState.ageSelected && formState.ageSelected.value} onChange={handleChange} name="ageSelected" id="ageSelected">
                            <option hidden value='Age'>Age</option>
                            {ages.map((age, index) => (
                                <option key={index} value={age.value}>
                                    {age.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={`form-field ${formState.organizationCategory && formState.organizationCategory.focus ? 'active' : ''}`}>
                        <label className={formState.organizationCategory && formState.organizationCategory?.error && 'red'}>
                            {formState.organizationCategory && formState.organizationCategory?.error || "Which best describes your organization?"}
                        </label>
                        <select value={formState.organizationSelected && formState.organizationSelected.value} onChange={handleChange} name="organizationSelected" id="organizationSelected">
                            <option hidden value='Category'>Category</option>
                            {organizationCategories.map((organizationCategory, index) => (
                                <option key={index} value={organizationCategory.value}>
                                    {organizationCategory.label}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>
                <div className={`page step-3 ${(pageState != 2 && pageState != 3 && pageState != 5 && pageState != 6) ? '' : 'hide' }`}>
                    <div className={`form-field ${formState.participant && formState.participant.focus ? 'active' : ''}`}>
                        <label className={formState.participant && formState.participant?.error && 'red'}>
                            {formState.participant && formState.participant?.error || "Has your organization ever participated in a barrel selection before"}
                        </label>
                        <select value={formState.participatedSelected && formState.participatedSelected.value} onChange={handleChange} name="participatedSelected" id="participatedSelected">
                            <option hidden value='Choose one of the following '>Choose one of the following </option>
                            {participated.map((participant, index) => (
                                <option key={index} value={participant.value}>
                                    {participant.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={`page step-4 ${(pageState != 2 && pageState != 3 && pageState != 4 && pageState != 6) ? '' : 'hide' }`}>
                    <div className={`form-field ${formState.barrelOption && formState.barrelOption.focus ? 'active' : ''}`}>
                        <label className={formState.barrelOption && formState.barrelOption?.error && 'red'}>
                            {formState.barrelOption && formState.barrelOption?.error || "If given the option, would you prefer a single barrel or a micro-blend of 2-3 barrels? (Estimated Barrel Yields are 170 - 215 bottles at cask strength.)"}
                        </label>
                        <select value={formState.barrelOptionSelected && formState.barrelOptionSelected.value} onChange={handleChange} name="barrelOptionSelected" id="barrelOptionSelected">
                            <option hidden value='Choose one of the following'>Choose one of the following</option>
                            {barrelOptions.map((barrelOption, index) => (
                                <option key={index} value={barrelOption.value}>
                                    {barrelOption.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={`page step-5 ${(pageState != 2 && pageState != 3 && pageState != 4 && pageState != 5) ? '' : 'hide' }`}>

                    <div className="row">
                        <div className={`form-field ${formState.chosen && formState.chosen.focus ? 'active' : ''}`}>
                            <label className={formState.chosen && formState.chosen?.error && 'red'}>
                                {formState.chosen && formState.chosen?.error || "If CHOSEN, what would you like to appear on the label that identifies your group/organization: "}
                            </label>
                            <input
                                id="chosen"
                                type="text"
                                name="chosen"
                                placeholder='Text (30 character limit)'
                                maxLength={30}
                                value={formState.chosen ? formState.chosen.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className={`form-field ${formState.logo && formState.logo.focus ? 'active' : ''}`}>
                            <label className={formState.logo && formState.logo?.error && 'red'}>
                                {formState.logo && formState.logo?.error || "Logo"}
                            </label>
                            <input
                                id="logo"
                                type="file"
                                name="logo"
                                className={formState.logo && formState.logo.value && 'hide-file'}
                                onChange={handleInputChange}
                                placeholder={formState.logo && formState.logo.value ? formState.logo.value : "No file selected"}
                            />
                            {formState.logo && formState.logo.value && (
                                <p className='file-text'>Selected file: {formState.logo.value}</p>
                            )}
                        </div>
                    </div>

                </div>
                <div className="row">
                    {/* <div className={`form-field`}>
                    <button 
                        type={pageState === 5 ? "submit" : "button"} 
                        className="btn" 
                        onClick={pageState === 5 ? handleFormSubmit : () => setPageState(++page_num)}
                        >
                        {pageState === 5 ? "Submit" : "Next"}
                    </button>

                    </div> */}

                    {/* <div className={`form-field ${(pageState == 1 || pageState == 2 || pageState == 3 || pageState == 4 ) ? '' : 'hide' }`}>
                        <button
                            type='button'
                            className="btn"
                            onClick={() => setPageState(++page_num)}
                        >
                            Next
                        </button>
                    </div>

                    <div className={`form-field ${(pageState != 5) ? 'hide' : '' }`}>
                        <button className="btn" type="submit" onClick={handleFormSubmit}>
                            Next
                        </button>
                    </div> */}

                    <div className={`form-field`}>
                        <button 
                            className="btn next" 
                            type="button" 
                            onClick={(event) => {
                                //get each input & select and make sure they are not empty
                                let inputs = Array.from(document.querySelectorAll('input')).filter(input => {
                                    return !input.closest('.page.hide');
                                });

                                let selects = Array.from(document.querySelectorAll('select')).filter(select => {
                                    return !select.closest('.page.hide');
                                });

                                console.log({inputs, selects});

                                let inputArray = Array.from(inputs);
                                let selectArray = Array.from(selects);

                                let emptyError = false;

                                inputArray.forEach(input => {
                                    //check if input name is in formState
                                    if (formState[input.name] === undefined) {
                                    emptyError = true;
                                    }
                                });
                                selectArray.forEach(select => {
                                    //check if select name is in formState
                                    if (formState[select.name] === undefined) {
                                    emptyError = true;
                                    }
                                });
                                console.log(formState);
                                if (emptyError) {
                                    alert('Please fill out all fields');
                                } else if (formError) {
                                    // handle form error
                                } else {
                                    if (pageState === 6) {
                                        handleFormSubmit(event);
                                        let imageWrapper = document.querySelector('.image-wrapper');
                                        imageWrapper.classList = 'image-wrapper customizer';
                                    }
                                    setPageState(++page_num);
                                }
                                console.log('scrolling')
                                setTimeout(() => {
                                    window.scrollTo(0, 0);
                                }, 100);
                            }}>
                            {pageState === 6 ? "Start Designing" : "Next"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div className='background-color'>
            {/* <img src={Bottle} alt='liquor bottle'/> */}
            <div id='butterfly-7'>
                <div className='pulse butterfly-7'>
                    <img src={butterfly} />
                </div>
            </div>
        </div>
    </div>
  )
}
