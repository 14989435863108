import React, { useEffect, useRef } from 'react';

import Button from './Button.js'

//images
import butterfly from '../assets/images/beautiful-butterfly.png'
import rules from '../assets/files/rules-conditions.pdf'

const download_btn =  {
    file : '../assets/files/rules.pdf',
    text : 'Rules & Conditions'
};
const begin_btn =  {
  text : 'Begin',
  class : 'continue'
};


export default function Hero({setCurrentComponent, setPageState, pageState}) {

  let butterfly1Ref = React.useRef(null);
  let butterfly2Ref = React.useRef(null);
  let butterfly3Ref = React.useRef(null);
  let butterfly4Ref = React.useRef(null);
  let butterfly5Ref = React.useRef(null);


  const handleBeginButton = () => {
    setPageState(2);
    let pageAnimation = document.getElementById('page-animation');
    pageAnimation.classList.remove('hide');
  };

  useEffect(() => {
    for (let i = 1; i <= 6; i++) {
      let butterfly = document.getElementById(`butterfly-${i}`);
      butterfly.addEventListener("animationend", function() {
        butterfly.querySelector('.pulse').classList.remove("pulse");
      });
    }
  }, []);
  


  return (
    <div id="hero">
        <div className='text-wrapper'>
          <h1>CHOSEN</h1>
          <div className='text-content'>
              <p>
                Blue Run Spirits is excited to launch the inaugural year of our Private Barrel Program, CHOSEN!
              </p>
              <p>
                Inspired by the most exciting aspects of America's native spirit and favorite pastimes, our program will create unforgettable experiences for our supporters.
              </p>
              <p>
                The 2023-2024 program is segmented into five phases:
              </p>
              <p>
                SCOUTING - THE PORTAL - THE BLUE RUN DRAFT - THE SEASON - THE GALA
              </p>
              <p>
                16 extremely lucky participants will be randomly selected for the opportunity to participate in selecting our very first private barrels to go to market.
              </p>
          </div>
          <a href={rules} target="_blank">Rules & Conditions</a>
          <button className="btn continue" onClick={handleBeginButton}>Begin</button>
        </div>
        <div id="butterfly-1">
          <div className='pulse butterfly-1'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-2'>
          <div ref={butterfly2Ref} className='pulse butterfly-2'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-3'>
          <div className='pulse butterfly-3'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-4'>
          <div className='pulse butterfly-4'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-5'>
          <div className='pulse butterfly-5'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-6'>
          <div className='pulse butterfly-6'>
            <img src={butterfly} />
          </div>
        </div>
    </div>
  )
}
