import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// retrieve Firebase configuration data from environment variables
const apiKey = process.env.FIREBASE_API_KEY;
const authDomain = process.env.AUTH_DOMAIN;
const projectId = process.env.PROJECT_ID;
const storageBucket = 'blue-run-spirits.appspot.com';
const messagingSenderId = process.env.MESSAGING_SENDER_ID;
const appId = process.env.APP_ID;
const measurementId = process.env.MEASUREMENT_ID;

const firebaseConfig = {
    apiKey: "AIzaSyCEnYgGL_TuS2G1qvDhhJkhBAqQWZegcXc",
    authDomain: "blue-run-spirits.firebaseapp.com",
    projectId: "blue-run-spirits",
    storageBucket: "blue-run-spirits.appspot.com",
    messagingSenderId: "468454966218",
    appId: "1:468454966218:web:8d4f699affc50fbdaf0533",
    measurementId: "G-R2YHEJ76P1"
};
// init firebase
const firebaseApp = initializeApp(firebaseConfig);

// initialize services
const db = getFirestore(firebaseApp);

export { db };