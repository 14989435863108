import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { runTransaction, writeBatch, getDoc, doc,setDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

//Images
import butterfly from '../assets/images/beautiful-butterfly.png'
import facebook from '../assets/images/socials/facebook.svg'
import instagram from '../assets/images/socials/instagram.svg'
import twitter from '../assets/images/socials/twitter.svg'
import tiktok from '../assets/images/socials/tiktok.svg'
import logo from '../assets/images/logo.png'


export default function PortalClosed({setCurrentComponent, handleBackButton, setPageState, pageState, formState, setFormState}) {

    let page_num = pageState

    const nameRegex = /^[a-zA-Z\s]+$/;
    const organizationRegex = /^[a-zA-Z0-9\s-']+$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    const stateRegex = /^(A[LKSZRAP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[DLNA]|K[SY]|LA|M[EHDAINSOT]|N[EVHJMYCD]|O[HKR]|P[WAR]|RI|S[CD]|T[NX]|UT|V[TIA]|W[AVIY])$/;
    const cityRegex = /^[a-zA-Z\s]+(?:[\s-][a-zA-Z\s]+)*$/;

    const states = [
        { label: 'AL', value: 'AL' },
        { label: 'AK', value: 'AK' },
        { label: 'AZ', value: 'AZ' },
        { label: 'AR', value: 'AR' },
        { label: 'CA', value: 'CA' },
        { label: 'CO', value: 'CO' },
        { label: 'CT', value: 'CT' },
        { label: 'DC', value: 'DC' },
        { label: 'DE', value: 'DE' },
        { label: 'FL', value: 'FL' },
        { label: 'GA', value: 'GA' },
        { label: 'HI', value: 'HI' },
        { label: 'ID', value: 'ID' },
        { label: 'IL', value: 'IL' },
        { label: 'IN', value: 'IN' },
        { label: 'IA', value: 'IA' },
        { label: 'KS', value: 'KS' },
        { label: 'KY', value: 'KY' },
        { label: 'LA', value: 'LA' },
        { label: 'ME', value: 'ME' },
        { label: 'MD', value: 'MD' },
        { label: 'MA', value: 'MA' },
        { label: 'MI', value: 'MI' },
        { label: 'MN', value: 'MN' },
        { label: 'MS', value: 'MS' },
        { label: 'MO', value: 'MO' },
        { label: 'MT', value: 'MT' },
        { label: 'NE', value: 'NE' },
        { label: 'NV', value: 'NV' },
        { label: 'NH', value: 'NH' },
        { label: 'NJ', value: 'NJ' },
        { label: 'NM', value: 'NM' },
        { label: 'NY', value: 'NY' },
        { label: 'NC', value: 'NC' },
        { label: 'ND', value: 'ND' },
        { label: 'OH', value: 'OH' },
        { label: 'OK', value: 'OK' },
        { label: 'OR', value: 'OR' },
        { label: 'PA', value: 'PA' },
        { label: 'RI', value: 'RI' },
        { label: 'SC', value: 'SC' },
        { label: 'SD', value: 'SD' },
        { label: 'TN', value: 'TN' },
        { label: 'TX', value: 'TX' },
        { label: 'UT', value: 'UT' },
        { label: 'VT', value: 'VT' },
        { label: 'VA', value: 'VA' },
        { label: 'WA', value: 'WA' },
        { label: 'WV', value: 'WV' },
        { label: 'WI', value: 'WI' },
        { label: 'WY', value: 'WY' }
    ];

    const submissionCategories = [
        {label: 'On-Premise Spirits Business', value: 'On-Premise Spirits Business'},
        {label: 'Off-Premise Spirits Business', value: 'Off-Premise Spirits Business'},
        {label: 'Non-Spirits Related Business', value: 'Non-Spirits Related Business'},
        {label: 'Spirits Society', value: 'Spirits Society'},
        {label: 'Non-Profit Organization', value: 'Non-Profit Organization'},
        {label: 'Individual', value: 'Individual'}
    ]


    //form interactions
    const [formError, setFormError] = useState(false);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;


        setFormState((prevState) => ({
            ...prevState,
            [name]: {
            value: value,
            focus: prevState[name] ? prevState[name].focus : false,
            error: prevState[name] ? prevState[name].error : undefined
            }
        }));
        console.log({target, value, name})
    
    }

        const handleFocus = (event) => {
            const target = event.target;
            const name = target.name;

            setFormState((prevState) => ({
                ...prevState,
                [name]: {
                value: prevState[name] ? prevState[name].value : '',
                focus: true,
                error: prevState[name] ? prevState[name].error : undefined
                }
            }));
        }

        const handleBlur = (event) => {
            const target = event.target;
            const name = target.name;
            const value = target.value;
            let error;

            if (name == 'email') {
              if(!emailRegex.test(value)){
                error = 'Invalid Email';
                setFormError(true);
              } else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if (name == 'phone') {
              if(!phoneRegex.test(value)){
                error = 'Invalid Phone Number';
                setFormError(true);
              }
              else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if (name == 'fullName') {
              if(!nameRegex.test(value)){
                error = 'Invalid Full Name';
                setFormError(true);
              }
              else {
                setFormError(false);
              }
              console.log(formError)
            }
            else if(name =='state'){
                if(!stateRegex.test(value)){
                    error = 'Invalid State, format should be "WA"';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else if(name =='city'){
                if(!cityRegex.test(value)){
                    error = 'Invalid character(s) in City';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }
            else { //submissionType
                if(!organizationRegex.test(value)){
                    error = 'Invalid character(s) in Organization';
                    setFormError(true);
                }
                else {
                    setFormError(false);
                }
                console.log(formError)
            }


            if (value === '') {
              setFormState((prevState) => ({
                ...prevState,
                [name]: {
                  value: '',
                  focus: false,
                  error: undefined
                }
              }));
            } else {
              setFormState((prevState) => ({
                ...prevState,
                [name]: {
                  value: prevState[name] ? prevState[name].value : '',
                  focus: true,
                  error
                }
              }));
            }
        }

    useEffect(() => {
        let inputFields = [];
        if (pageState === 'step-1') {
            inputFields = ['fullName', 'email', 'phone', 'submissionType', 'city', 'state' ];
        }
        inputFields.forEach((field, index) => {
            if (formState[field]) {
                document.querySelector(`#${field}`).value = formState[field].value;
                if (formState[field].value != '') {
                    formState[field].focus = true;
                } else {
                    formState[field].focus = false;
                }
                document.querySelector(`#${field}`).setAttribute("key", index);
            }
        });
    }, [formState]);

    useEffect(() => {
        let butterfly = document.getElementById(`butterfly-7`);
        butterfly.addEventListener("animationend", function() {
            if (butterfly) {
                butterfly.querySelector('.pulse').classList.remove("pulse");
            }   
        });
      }, []);

    const handleChange = (event) => {
        const { value } = event.target;
        const name = event.target.name;
        const selectValue = event.target.value;
        console.log({value, name});
        setFormState((prevState) => ({
          ...prevState,
          [name]: {
            value: value,
            focus: prevState[name] ? prevState[name].focus : false,
            error: prevState[name] ? prevState[name].error : undefined
          }
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log(formState);
        console.log(formError);
        try {
          if(!formError) {
            setPageState(2)

            let formData = {};
            
            for (let key in formState) {
              if (formState.hasOwnProperty(key)) {
                formData[key] = formState[key].value;
              }
            }

            console.log(formData);

            // Generate a unique access code
            await addDoc(collection(db, 'portal_closed'), {
                formSubmission: formData,
            });

            console.log('Document written');

            console.log(formState.email.value);
            let hubEmail = formState.email.value;
            await axios({
                method: 'post',
                url: `https://us-central1-blue-run-spirits.cloudfunctions.net/app`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data : {
                    "properties": {
                        "email": hubEmail
                    }   
                }
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
            });

          }

        } catch (error) {
          console.log(error);
        }
    }


  return (
    <div id='portal-closed'>
    <a className='logo' href="/"><img src={logo} /></a>
        <div className={`form-wrapper`}>
            <form id='contact'>
                <div className={`page step-1 ${(pageState == 1) ? '' : 'hide' }`}>
                    {/* Full Name */}
                    <h3>The 2023 Draft Portal has officially closed.</h3>
                    <p>Please provide the information below to ensure you are included in communications about our 2024 Private Barrel Program.</p>
                    <div className="row">
                        <div className={`form-field ${formState.fullName && formState.fullName.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.fullName && formState.fullName?.error && 'red'}`}>
                                {formState.fullName && formState.fullName?.error}
                            </label>
                            <input
                                id="fullName"
                                type="text"
                                name="fullName"
                                placeholder='Full Name'
                                value={formState.fullName ? formState.fullName.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Email */}
                    <div className="row">
                        <div className={`form-field ${formState.email && formState.email.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.email &&  formState.email?.error && 'red'}`}>
                            {formState.email &&  formState.email?.error}
                            </label>
                            <input
                                id="email"
                                type="text"
                                name="email"
                                placeholder='Email'
                                value={formState.email ? formState.email.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Phone */}
                    <div className="row">
                        <div className={`form-field ${formState.phone && formState.phone.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.phone && formState.phone?.error && 'red'}`}>
                                {formState.phone && formState.phone?.error}
                            </label>
                            <input
                                id="phone"
                                type="text"
                                name="phone"
                                placeholder='Phone'
                                value={formState.phone ? formState.phone.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {/* Submission Name */}
                    <div className="row">
                        <div className={`form-field ${formState.submissionType && formState.submissionType.focus ? 'active' : ''}`}>
                            <label className={`${formState.submissionType && formState.submissionType.error ? 'red' : ''}`}>
                                {formState.submissionType && formState.submissionType?.error ? formState.submissionType.error : "Choose the best one that represents who you're reaching out on-behalf of"}
                            </label>
                            <select onChange={handleChange} name="submissionType" id="submissionType" value={formState.submissionType && formState.submissionType.value}>
                                <option hidden value=''>Select An Option</option>
                                {submissionCategories.map((cat, index) => (
                                    <option key={index} value={cat.value}>
                                        {cat.label}
                                    </option>
                                ))}
                            </select>
                            <p className='org-text'>If you are not submitting on behalf of an organization, please type "Individual"</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`form-field ${formState.city && formState.city.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.city && formState.city.error ? 'red' : ''}`}>
                                {formState.city && formState.city?.error}
                            </label>
                            <input
                                id="city"
                                type="text"
                                name="city"
                                placeholder='City'
                                value={formState.city ? formState.city.value : ''}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className={`form-field ${formState.state && formState.state.focus ? 'active' : ''}`}>
                            <label className={`no-margin ${formState.state && formState.state.error ? 'red' : ''}`}>
                                {formState.state && formState.state?.error}
                            </label>
                            <select onChange={handleChange} name="state" id="state" value={formState.state && formState.state.value}>
                                <option hidden value='State'>State</option>
                                {states.map((state, index) => (
                                    <option key={index} value={state.value}>
                                        {state.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className={`row ${(pageState == 1) ? '' : 'hide'}`}>
                    <div className={`form-field`}>
                        <button 
                            className="btn next" 
                            type="button" 
                            onClick={(event) => {
                                //get each input & select and make sure they are not empty
                                let inputs = Array.from(document.querySelectorAll('input')).filter(input => {
                                    return !input.closest('.page.hide');
                                });

                                let selects = Array.from(document.querySelectorAll('select')).filter(select => {
                                    return !select.closest('.page.hide');
                                });

                                console.log({inputs, selects});

                                let inputArray = Array.from(inputs);
                                let selectArray = Array.from(selects);

                                let emptyError = false;

                                inputArray.forEach(input => {
                                    //check if input name is in formState
                                    if (formState[input.name] === undefined) {
                                    emptyError = true;
                                    }
                                });
                                selectArray.forEach(select => {
                                    //check if select name is in formState
                                    if (formState[select.name] === undefined) {
                                    emptyError = true;
                                    }
                                });
                                console.log(formState);
                                if (emptyError) {
                                    alert('Please fill out all fields');
                                } else if (formError) {
                                    // handle form error
                                } else {
                                    handleFormSubmit(event);
                                }
                                setTimeout(() => {
                                    window.scrollTo(0, 0);
                                }, 100);
                            }}>
                            Submit
                        </button>
                    </div>
                </div>
                <div className={`row ${(pageState == 2) ? '' : 'hide'}`}>
                    <div className={`form-field submit-text`}>
                        <h3>Submission Received</h3>
                        <p className='small '>Tune in March 23rd to social channels to see who is chosen 7pm EST</p>
                        <div className='social-icons'>
                            <a href='https://www.facebook.com/bluerunspirits/' target='_blank' rel='noopener noreferrer'>
                                <img src={facebook} alt="facebook" />
                            </a>
                            <a href='https://www.instagram.com/bluerunspirits/' target='_blank' rel='noopener noreferrer'>
                                <img src={instagram} alt="instagram" />
                            </a>
                            <a href='https://twitter.com/bluerunspirits' target='_blank' rel='noopener noreferrer'>
                                <img src={twitter} alt="twitter" />
                            </a>
                            <a href='https://tiktok.com/@bluerunspirits' target='_blank' rel='noopener noreferrer'>
                                <img src={tiktok} alt="tiktok" />
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div className='background-color'>
            <div id='butterfly-7' className='step3'>
                <div className='pulse butterfly-7 step3'>
                    <img src={butterfly} />
                </div>
            </div>
        </div>
        <div id='text-wrapper'>
            <h3>Draft Day</h3>
            <p>Come back on <h4>March 23rd</h4> to see if you've been</p>
            <h2>"Chosen".</h2>
        </div>
    </div>
  )
}
