import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


//Images
import logo from './assets/images/logo.png';

import Bottle from './assets/images/base-bottle.png';
import CleanWhiteButterfly from './assets/images/butterfly/clean-white-butterfly.png';
import CleanWhiteNeck from './assets/images/neck/clean-white-neck.png';
import CleanWhiteProofLabel from './assets/images/proof-label/clean-white-proof.png';
import CleanWhiteVerticalStrip from './assets/images/vertical-strip/clean-white-vertical.png';

import mysteryBottle from './assets/images/mystery-bottle.png';

//Styles
import './App.css';
import './assets/scss/style.css';

//Components
import Hero from './components/Hero';
import Form from './components/Form';
import Customizer from './components/Customizer';
import Submit from './components/Submit';
import ProtectedPage from './components/ProtectedPage';
import LoginPage from './components/LoginPage';
import PortalClosed from './components/PortalClosed';
import HidePage from './components/HidePage';




function App() {


  const [pageState, setPageState] = useState(1);
  const [currentComponent, setCurrentComponent] = useState('Hero');
  const [bottleState, setBottleState] = useState({
    butterflies: false,
    necks: false,
    verticalStrips: false,
    proofLabels: false,
  });
  const [colorChoice, setColorChoice] = useState({
    butterflies: '',
    necks: '',
    verticalStrips: '',
    proofLabels: '',
  });
  const [formState, setFormState] = useState({});
  const [hidePage, setHidePage] = useState(true);

  const handleBackButton = () => {
    setPageState(pageState - 1);
  };

  const urlForLogin = process.env.REACT_APP_HIDDEN_URL;

  useEffect(() => {

    // if url path is not /secret-protected-url)
    if (window.location.pathname == "/bottle-customizer-form" && hidePage == false) {

      console.log(pageState);
      let imageWrapper = document.querySelector('.image-wrapper');
      let pageAnimation = document.getElementById('page-animation');
      let butterfly7 = document.getElementById('butterfly-7');
      let animationClass = 'step' + pageState;

      if (pageState == 1) {
        setCurrentComponent('Hero');
        imageWrapper.classList.add('hide');
      }
      if (pageState > 1) {
        imageWrapper.classList.remove('hide');
      }
      if (pageState == 2 || pageState == 3 || pageState == 4 || pageState == 5 || pageState == 6) {
          setCurrentComponent('Form');
          imageWrapper.classList = 'image-wrapper';
          if (butterfly7) {
            butterfly7.classList = animationClass;
          }
      } 
      if (pageState === 7) { 
          setCurrentComponent('Customizer');
          imageWrapper.classList = 'image-wrapper customizer';
      }
      if (pageState === 8) {
          setCurrentComponent('Submit');
      }

      pageAnimation.addEventListener("animationend", function() {
        pageAnimation.classList.add("hide");
      });

    }

  }, [pageState]);

    const [isLoggedIn, setIsLoggedIn] = useState(false); // Keep track of login state
  
    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
        // Verify the token with the server to ensure it's still valid
        // and update the state of isLoggedIn accordingly
        // You can do this by sending a request to a server endpoint that checks the token's validity
        setIsLoggedIn(true);
      }
    }, []);


    const handleLogin = (password) => {
      // Check if the password is correct
      if (password === 'mySecretPassword') {
        setIsLoggedIn(true);
      }
    };
  
    const handleLogout = () => {
      setIsLoggedIn(false);
    };
  
    return (
      <div className="App">
        <BrowserRouter>
            <Routes>
              {/* Protected page */}
              <Route path={urlForLogin}
                  element={
                  isLoggedIn ? (
                    <ProtectedPage handleLogout={handleLogout} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                  ) : (
                    <LoginPage handleLogin={handleLogin} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                  )
                }
              >
              </Route>
              {/* Protected page */}
              <Route path="*"
                  element={
                    <React.Fragment>
                      <div className='image-wrapper mystery'>
                        <img className='bottle' src={mysteryBottle} alt='bottle' />
                      </div>
                      <PortalClosed formState={formState} setFormState={setFormState} setPageState={setPageState} pageState={pageState} />
                    </React.Fragment>
                  }
              >
              </Route>
              {/* Access the full experience */}
              <Route path="/bottle-customizer-form" 
                element={
                    <React.Fragment>
                    { hidePage &&
                      <HidePage setHidePage={setHidePage} />
                    }
                    { hidePage == false &&
                      <>
                        <div id="page-animation" className='hide'></div>
                        <a className='logo' href="/"><img src={logo} /></a>
                        { pageState !== 1 &&
                          <button
                              type='button'
                              className="btn global-back"
                              onClick={handleBackButton}
                          >
                              Back
                          </button>
                        }
                        <div className='image-wrapper hide'>
                          <img className='bottle' src={Bottle} alt='bottle' />
                          <img className='Butterflies' src={CleanWhiteButterfly} alt='Clean White Butterfly' />
                          <img className='Necks' src={CleanWhiteNeck} alt='neck' />
                          <img className='ProofLabels' src={CleanWhiteProofLabel} alt='proof-label' />
                          <img className='VerticalStrips' src={CleanWhiteVerticalStrip} alt='vertical-strip' />
                        </div>
                          {currentComponent == 'Hero' ? <Hero currentComponent={currentComponent} setCurrentComponent={setCurrentComponent} setPageState={setPageState} pageState={pageState} /> : ''}
                          {currentComponent == 'Form' ? <Form currentComponent={currentComponent} setCurrentComponent={setCurrentComponent} handleBackButton={handleBackButton} setPageState={setPageState} pageState={pageState} formState={formState} setFormState={setFormState}  /> : ''}
                          {currentComponent == 'Customizer' ? <Customizer colorChoice={colorChoice} setColorChoice={setColorChoice} currentComponent={currentComponent} setCurrentComponent={setCurrentComponent} setPageState={setPageState} bottleState={bottleState} setBottleState={setBottleState} /> : ''}
                          {currentComponent == 'Submit' ? <Submit colorChoice={colorChoice} currentComponent={currentComponent} setCurrentComponent={setCurrentComponent} formState={formState} /> : ''}  
                      </>
                    }
                    </React.Fragment>
                  }
                >
              </Route>
            </Routes>
        </BrowserRouter>
      </div>
    );
  
}

export default App;