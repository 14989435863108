import React, { useState, useEffect } from 'react';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { runTransaction, writeBatch, getDocs, doc,setDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

function ProtectedPage({ isLoggedIn, setIsLoggedIn }) {

    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
    };

    const [data, setData] = useState([]);
    const [closedData, setClosedData] = useState([]);
    const [postPortalData, setPostPortalData] = useState([]);
    const [isClosed, setIsClosed] = useState(false);
    const [isOriginal, setIsOriginal] = useState(false);
    const [isPostPortal, setIsPostPortal] = useState(true);
    useEffect(() => {
        async function getCollection() {
          let tempData = [];
          const querySnapshot = await getDocs(collection(db, "submissions"));
          querySnapshot.forEach((doc) => {
            if (doc) {
              tempData.push(doc.data());
            }
          });
          setData(tempData);
        }
        getCollection();

        async function getClosedCollection() {
          let tempData = [];
          const querySnapshot = await getDocs(collection(db, "portal_closed"));
          querySnapshot.forEach((doc) => {
            if (doc) {
              tempData.push(doc.data());
            }
          });
          setClosedData(tempData);
        }
        getClosedCollection();
        console.log(data);

        async function getPostPortalCollection() {
          let tempData = [];
          const querySnapshot = await getDocs(collection(db, "post_portal_submissions"));
          querySnapshot.forEach((doc) => {
            if (doc) {
              tempData.push(doc.data());
            }
          });
          setPostPortalData(tempData);
        }
        getPostPortalCollection();
    }, []);

    const downloadCsv = () => {
        const csvData = [];
        data.forEach((item) => {
          const csvItem = {
            "Butterfly": item.colorChoice?.butterflies || "",
            "Neck": item.colorChoice?.necks || "",
            "Proof Label": item.colorChoice?.proofLabels || "",
            "Vertical Strip": item.colorChoice?.verticalStrips || "",
            "Full Name": item.formSubmission?.fullName || "",
            "Email": item.formSubmission?.email || "",
            "Phone": item.formSubmission?.phone || "",
            "Chosen": item.formSubmission?.chosen || "",
            "Address 1": item.formSubmission?.address1 || "",
            "City": item.formSubmission?.city || "",
            "State": item.formSubmission?.stateSelected || "",
            "Zip": item.formSubmission?.zip || "",
            "Organization Name": item.formSubmission?.organizationName || "",
            "Organization Selected": item.formSubmission?.organizationSelected || "",
            "Participated Selected": item.formSubmission?.participatedSelected || "",
          };
          csvData.push(csvItem);
        });
      
        const csvRows = [];
        const headers = Object.keys(csvData[0]);
        csvRows.push(headers.join(","));
      
        for (const row of csvData) {
          const values = headers.map((header) => {
            const escaped = `${row[header]}`.replace(/"/g, '\\"');
            return `"${escaped}"`;
          });
          csvRows.push(values.join(","));
        }
      
        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "data.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
    }; 

    const downloadPostPortalCsv = () => {
      const csvData = [];
      postPortalData.forEach((item) => {
        const csvItem = {
          "Butterfly": item.colorChoice?.butterflies || "",
          "Neck": item.colorChoice?.necks || "",
          "Proof Label": item.colorChoice?.proofLabels || "",
          "Vertical Strip": item.colorChoice?.verticalStrips || "",
          "Full Name": item.formSubmission?.fullName || "",
          "Email": item.formSubmission?.email || "",
          "Phone": item.formSubmission?.phone || "",
          "Chosen": item.formSubmission?.chosen || "",
          "Address 1": item.formSubmission?.address1 || "",
          "City": item.formSubmission?.city || "",
          "State": item.formSubmission?.stateSelected || "",
          "Zip": item.formSubmission?.zip || "",
          "Organization Name": item.formSubmission?.organizationName || "",
          "Organization Selected": item.formSubmission?.organizationSelected || "",
          "Participated Selected": item.formSubmission?.participatedSelected || "",
        };
        csvData.push(csvItem);
      });
    
      const csvRows = [];
      const headers = Object.keys(csvData[0]);
      csvRows.push(headers.join(","));
    
      for (const row of csvData) {
        const values = headers.map((header) => {
          const escaped = `${row[header]}`.replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
    
      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  }; 

    const downloadClosedCsv = () => {
      const csvData = [];
      closedData.forEach((item) => {
        const csvItem = {
          "Full Name": item.formSubmission?.fullName || "",
          "Email": item.formSubmission?.email || "",
          "Phone": item.formSubmission?.phone || "",
          "submissionType": item.formSubmission?.submissionType || "",
          "City": item.formSubmission?.city || "",
          "State": item.formSubmission?.stateSelected || "",
        };
        csvData.push(csvItem);
      });
    
      const csvRows = [];
      const headers = Object.keys(csvData[0]);
      csvRows.push(headers.join(","));
    
      for (const row of csvData) {
        const values = headers.map((header) => {
          const escaped = `${row[header]}`.replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
    
      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  }; 


    return (
      <div id="logged-in">
        <div className="top-content">
            <h1>Data</h1>
            <div className='button-wrapper'>
              <button onClick={handleLogout}>Logout</button>
              <button onClick={downloadCsv}>Download CSV</button>
              <button onClick={downloadClosedCsv}>Download Closed CSV</button>
              <button onClick={downloadPostPortalCsv}>Download Post Portal CSV</button>
            </div>
            <div className='button-wrapper'>
              <button onClick={
                  () => {
                    setIsPostPortal(false)
                    setIsClosed(true)
                    setIsOriginal(false)
                  }
              }>
                Show Closed Portal Submissions
              </button>
              <button onClick={
                  () => {
                    setIsPostPortal(false)
                    setIsClosed(false)
                    setIsOriginal(true)
                  }
              }>
                Show Original Portal Submissions
              </button>
              <button onClick={
                  () => {
                    setIsPostPortal(true)
                    setIsClosed(false)
                    setIsOriginal(false)
                  }
              }>
                Show Post Portal Submissions
              </button>
            </div>  

        </div>
        <div className="data-wrapper">
        {isOriginal && <h3>Original Portal Submissions</h3>}
        {isClosed && <h3>Closed Portal Submissions</h3>}
        {isPostPortal && <h3>Post Portal Submissions</h3>}
        {isOriginal && data.map((item, index) => (
            <div className="data-item" key={index}>
            {item.colorChoice && (
                <div className="color-choice">
                <h2>Color Choice</h2>
                <p><strong>Butterflies:</strong> {item.colorChoice.butterflies}</p>
                <p><strong>Necks:</strong> {item.colorChoice.necks}</p>
                <p><strong>Proof Labels:</strong> {item.colorChoice.proofLabels}</p>
                <p><strong>Vertical Stripes:</strong> {item.colorChoice.verticalStrips}</p>
                </div>
            )}

            {item.formSubmission && (
                <div className="form-submission">
                <h2>Form Submission</h2>
                <p><strong>Full Name:</strong> {item.formSubmission.fullName}</p>
                <p><strong>Email:</strong> {item.formSubmission.email}</p>
                <p><strong>Phone:</strong> {item.formSubmission.phone}</p>
                <p><strong>Chosen:</strong> {item.formSubmission.chosen}</p>
                <p><strong>Address 1:</strong> {item.formSubmission.address1}</p>
                <p><strong>City:</strong> {item.formSubmission.city}</p>
                <p><strong>State:</strong> {item.formSubmission.stateSelected}</p>
                <p><strong>Zip:</strong> {item.formSubmission.zip}</p>
                <p><strong>Organization Name:</strong> {item.formSubmission.organizationName}</p>
                <p><strong>Organization Selected:</strong> {item.formSubmission.organizationSelected}</p>
                <p><strong>Participated Selected:</strong> {item.formSubmission.participatedSelected}</p>
                </div>
            )}

            <div className="file-url">
                <h2>File URL</h2>
                <a href={item.logo} target="_blank" rel="noreferrer">Logo</a>
            </div>
            </div>
        ))}
        {isPostPortal && postPortalData.map((item, index) => (
            <div className="data-item" key={index}>
            {item.colorChoice && (
                <div className="color-choice">
                <h2>Color Choice</h2>
                <p><strong>Butterflies:</strong> {item.colorChoice.butterflies}</p>
                <p><strong>Necks:</strong> {item.colorChoice.necks}</p>
                <p><strong>Proof Labels:</strong> {item.colorChoice.proofLabels}</p>
                <p><strong>Vertical Stripes:</strong> {item.colorChoice.verticalStrips}</p>
                </div>
            )}

            {item.formSubmission && (
                <div className="form-submission">
                <h2>Form Submission</h2>
                <p><strong>Full Name:</strong> {item.formSubmission.fullName}</p>
                <p><strong>Email:</strong> {item.formSubmission.email}</p>
                <p><strong>Phone:</strong> {item.formSubmission.phone}</p>
                <p><strong>Chosen:</strong> {item.formSubmission.chosen}</p>
                <p><strong>Address 1:</strong> {item.formSubmission.address1}</p>
                <p><strong>City:</strong> {item.formSubmission.city}</p>
                <p><strong>State:</strong> {item.formSubmission.stateSelected}</p>
                <p><strong>Zip:</strong> {item.formSubmission.zip}</p>
                <p><strong>Organization Name:</strong> {item.formSubmission.organizationName}</p>
                <p><strong>Organization Selected:</strong> {item.formSubmission.organizationSelected}</p>
                <p><strong>Participated Selected:</strong> {item.formSubmission.participatedSelected}</p>
                </div>
            )}

            <div className="file-url">
                <h2>File URL</h2>
                <a href={item.logo} target="_blank" rel="noreferrer">Logo</a>
            </div>
            </div>
        ))}
        {isClosed && closedData.map((item, index) => (
            <div className="data-item" key={index}>
            {item.formSubmission && (
              <div className="form-submission">
                <p><strong>Full Name:</strong> {item.formSubmission.fullName}</p>
                <p><strong>Email:</strong> {item.formSubmission.email}</p>
                <p><strong>Phone:</strong> {item.formSubmission.phone}</p>
                <p><strong>Submission Type:</strong> {item.formSubmission.submissionType}</p>
                <p><strong>City:</strong> {item.formSubmission.city}</p>
                <p><strong>State:</strong> {item.formSubmission.stateSelected}</p>
              </div>
            )}
            </div>
        ))}
        </div>
      </div>
    );
  }
  
  export default ProtectedPage;  