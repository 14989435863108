import React, { useState } from "react";

function HidePage({setHidePage}) {
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        // Check if the password is correct
        if (password === 'CHOSEN23!!') {
            setHidePage(false);
        } else {
            setMessage('Incorrect password. Please try again.');
        }
    };

  return (
    <div id="login">
      <h1>Please Enter Password To Start</h1>
        <form disabled>
            <div className="page">
                <div className="row">
                    <div className="form-field">
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleLogin(e)
                        }
                    }} />

                    </div>
                </div>
                <button className="btn" type="button" onClick={(e) => handleLogin(e)} >
                    Login
                </button>
            </div>
            {message && 
            <>
                <br /><p>{message}</p>
            </>
            }
        </form>
    </div>
  );
}

export default HidePage;
