import React, { useState } from 'react';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { runTransaction, writeBatch, getDoc, doc,setDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

//pat-na1-561e39aa-d5e2-4662-8fcd-ce99f12e16bf


import rules from '../assets/files/rules-conditions.pdf'
import butterfly from '../assets/images/beautiful-butterfly.png'

const download_btn =  {
    file : '../assets/files/rules-conditions.pdf',
    text : 'Rules & Conditions'
};


export default function Submit({setCurrentComponent, formState, colorChoice}) {


    let [checkboxClicked, setCheckboxClicked] = useState(false);

const handleSubmit = async (e, formState) => {
    e.preventDefault();

    //if checkbox is not checked, return
    if (!checkboxClicked) {
        //remove .hide from .agree-terms
        let agreeRules = document.getElementById('agree-rules');
        agreeRules.classList.remove('hide');
        return;
    }

    //remove .hide from .loading
    let loading = document.getElementById('loading');
    loading.classList.remove('hide');
    document.querySelector('.submit-wrapper').classList.add('hide');

    //loop through formData and get values, if key has file assign a file
    let formData = {};
    let file;
    
    for (let key in formState) {
      if (formState.hasOwnProperty(key)) {
        if (key === "logo") {
          file = formState[key].file;
        } else {
          formData[key] = formState[key].value;
        }
      }
    }
        
    try {
        const storage = getStorage();
        let downloadFileUrl;
        
        // Listen for state changes, errors, and completion of the upload.
        async function uploadFile(file) {
            // Create the file metadata
            /** @type {any} */
            const metadata = {
              contentType: file.type
            };
          
            // Upload file and metadata to the object 'images/mountains.jpg'
            const storageRef = ref(storage, 'images/' + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
          
            return new Promise(async(resolve, reject) => {
              uploadTask.on('state_changed',
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  console.log('Upload is ' + progress + '% done');
                    console.log(snapshot.state)
                  switch (snapshot.state) {
                    case 'paused':
                      console.log('Upload is paused');
                      break;
                    case 'running':
                      console.log('Upload is running');
                      break;
                  }
                  console.log(snapshot);
                }, 
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case 'storage/unauthorized':
                      // User doesn't have permission to access the object
                      break;
                    case 'storage/canceled':
                      // User canceled the upload
                      break;
              
                    // ...
              
                    case 'storage/unknown':
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                  }
                  reject(error);
                }, 
                () => {
                    resolve();
                }
              );
            });
          }
          
          async function main() {
            await uploadFile(file);
            console.log('File upload complete');
            // Upload completed successfully, now we can get the download URL
             // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: file.type
            };
            const storageRef = ref(storage, 'images/' + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                downloadFileUrl = downloadURL;
            });

            document.querySelector('.submitted-wrapper').classList.remove('hide');
            document.getElementById('loading').classList.add('hide');
            document.querySelector('.global-back').classList.add('hide');

            await addDoc(collection(db, 'post_portal_submissions'), {
                formSubmission: formData,
                logo: downloadFileUrl,
                colorChoice: colorChoice
            });

            console.log("Transaction successfully committed!");
            //console.log(token);
            console.log(formData.email);
            await axios({
                method: 'post',
                url: `https://us-central1-blue-run-spirits.cloudfunctions.net/app`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data : {
                    "properties": {
                        "email": formData.email
                    }   
                }
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
            });
            

          }
          
          main();
          
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
}


  return (
    <div id='submit'>
        <div className='submit-wrapper'>
            <h1>CHOSEN</h1>
            <p>Before submitting your entry, please ensure you’ve taken a close a look at our “Participation Rules and Conditions.” There are no frills or tricks in this document, but in order to run a fair and effective program we need everyone to have the same information and understand the commitment if “CHOSEN”.</p>
            <div className='checkbox-wrapper-outer'>
                <p id='agree-rules' className='hide'>Agree to Rules & Conditions to Continue</p>
                <div className='checkbox-wrapper'>
                    <input type={'checkbox'} id={'terms'} name={'terms'} 
                        onClick={() => {
                            setCheckboxClicked(!checkboxClicked)
                            let agreeRules = document.getElementById('agree-rules');
                            agreeRules.classList.add('hide');
                        }}
                    />
                    <label htmlFor={'terms'}>I am 21 year of age or older and agree to the Participation Rules and Conditions of the  Blue Run Spirits Private Barrel Program.</label>
                </div>
            </div>
            <a href={rules} target="_blank">Rules & Conditions</a>
            <button
                type='button'
                className="btn"
                onClick={(e) => handleSubmit(e, formState)}
            >
                Submit
            </button>
        </div>
        <div id="loading" className='hide'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className='submitted-wrapper hide'>
            <p>
                Thank you for sumbmitting your entry to The Portal. The Blue Run Spirits Draft with take place on <b>March 23rd at 7pm ET / 4pm PT</b> and be live-streamed the various Blue Run Spirits social media channels. Tune in to see if you have been <br />“<b>CHOSEN</b>”!
            </p>
        </div>
        <div id='butterfly-8'>
          <div className='pulse butterfly-6'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-9'>
          <div className='pulse butterfly-6'>
            <img src={butterfly} />
          </div>
        </div>
        <div id='butterfly-10'>
          <div className='pulse butterfly-6'>
            <img src={butterfly} />
          </div>
        </div>
    </div>
  )
}
