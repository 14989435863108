import { useState, useEffect } from 'react';

//Images
import Bottle from '../assets/images/base-bottle.png'
import BoldYellowButterfly from '../assets/images/butterfly/bold-yellow-butterfly.png'
import AthleticGrayButterfly from '../assets/images/butterfly/athletic-gray-butterfly.png'
import CleanWhiteButterfly from '../assets/images/butterfly/clean-white-butterfly.png'
import CrimsonRedButterfly from '../assets/images/butterfly/crimson-red-butterfly.png'
import NewNavyButterfly from '../assets/images/butterfly/new-navy-butterfly.png'
import PerfectSeasonPurpleButterfly from '../assets/images/butterfly/perfect-season-purple-butterfly.png'
import PitchBlackButterfly from '../assets/images/butterfly/pitch-black-butterfly.png'
import SunshineOrangeButterfly from '../assets/images/butterfly/sunshine-orange-butterfly.png'
import TrueBlueButterfly from '../assets/images/butterfly/true-blue-butterfly.png'
import TurfGreenButterfly from '../assets/images/butterfly/turf-green-butterfly.png'

import BoldYellowCut from '../assets/images/cut-butterflies/bold-yellow-cut.png'
import AthleticGrayCut from '../assets/images/cut-butterflies/athletic-gray-cut.png'
import CleanWhiteCut from '../assets/images/cut-butterflies/clean-white-cut.png'
import CrimsonRedCut from '../assets/images/cut-butterflies/crimson-red-cut.png'
import NewNavyCut from '../assets/images/cut-butterflies/new-navy-cut.png'
import PerfectSeasonPurpleCut from '../assets/images/cut-butterflies/perfect-season-purple-cut.png'
import PitchBlackCut from '../assets/images/cut-butterflies/pitch-black-cut.png'
import SunshineOrangeCut from '../assets/images/cut-butterflies/sunshine-orange-cut.png'
import TrueBlueCut from '../assets/images/cut-butterflies/true-blue-cut.png'
import TurfGreenCut from '../assets/images/cut-butterflies/turf-green-cut.png'

import BoldYellowNeck from '../assets/images/neck/bold-yellow-neck.png'
import AthleticGrayNeck from '../assets/images/neck/athletic-gray-neck.png'
import CleanWhiteNeck from '../assets/images/neck/clean-white-neck.png'
import CrimsonRedNeck from '../assets/images/neck/crimson-red-neck.png'
import NewNavyNeck from '../assets/images/neck/new-navy-neck.png'
import PerfectSeasonPurpleNeck from '../assets/images/neck/perfect-season-purple-neck.png'
import PitchBlackNeck from '../assets/images/neck/pitch-black-neck.png'
import SunshineOrangeNeck from '../assets/images/neck/sunshine-orange-neck.png'
import TrueBlueNeck from '../assets/images/neck/true-blue-neck.png'
import TurfGreenNeck from '../assets/images/neck/turf-green-neck.png'

import BoldYellowProofLabel from '../assets/images/proof-label/bold-yellow-proof.png'
import AthleticGrayProofLabel from '../assets/images/proof-label/athletic-gray-proof.png'
import CleanWhiteProofLabel from '../assets/images/proof-label/clean-white-proof.png'
import CrimsonRedProofLabel from '../assets/images/proof-label/crimson-red-proof.png'
import NewNavyProofLabel from '../assets/images/proof-label/new-navy-proof.png'
import PerfectSeasonPurpleProofLabel from '../assets/images/proof-label/perfect-season-purple-proof.png'
import PitchBlackProofLabel from '../assets/images/proof-label/pitch-black-proof.png'
import SunshineOrangeProofLabel from '../assets/images/proof-label/sunshine-orange-proof.png'
import TrueBlueProofLabel from '../assets/images/proof-label/true-blue-proof.png'
import TurfGreenProofLabel from '../assets/images/proof-label/turf-green-proof.png'

import BoldYellowVerticalStrip from '../assets/images/vertical-strip/bold-yellow-vertical.png'
import AthleticGrayVerticalStrip from '../assets/images/vertical-strip/athletic-gray-vertical.png'
import CleanWhiteVerticalStrip from '../assets/images/vertical-strip/clean-white-vertical.png'
import CrimsonRedVerticalStrip from '../assets/images/vertical-strip/crimson-red-vertical.png'
import NewNavyVerticalStrip from '../assets/images/vertical-strip/new-navy-vertical.png'
import PerfectSeasonPurpleVerticalStrip from '../assets/images/vertical-strip/perfect-season-purple-vertical.png'
import PitchBlackVerticalStrip from '../assets/images/vertical-strip/pitch-black-vertical.png'
import SunshineOrangeVerticalStrip from '../assets/images/vertical-strip/sunshine-orange-vertical.png'
import TrueBlueVerticalStrip from '../assets/images/vertical-strip/true-blue-vertical.png'
import TurfGreenVerticalStrip from '../assets/images/vertical-strip/turf-green-vertical.png'

const Butterflies = [
  {src: BoldYellowButterfly, alt: 'Bold Yellow Butterfly', dataColor: 'BoldYellow'},
  {src: AthleticGrayButterfly, alt: 'Athletic Gray Butterfly', dataColor: 'AthleticGray'},
  {src: CleanWhiteButterfly, alt: 'Clean White Butterfly', dataColor: 'CleanWhite'},
  {src: CrimsonRedButterfly, alt: 'Crimson Red Butterfly', dataColor: 'CrimsonRed'},
  {src: NewNavyButterfly, alt: 'New Navy Butterfly', dataColor: 'NewNavy'},
  {src: PerfectSeasonPurpleButterfly, alt: 'Perfect Season Purple Butterfly', dataColor: 'PerfectSeasonPurple'},
  {src: PitchBlackButterfly, alt: 'Pitch Black Butterfly', dataColor: 'PitchBlack'},
  {src: SunshineOrangeButterfly, alt: 'Sunshine Orange Butterfly', dataColor: 'SunshineOrange'},
  {src: TrueBlueButterfly, alt: 'True Blue Butterfly', dataColor: 'TrueBlue'},
  {src: TurfGreenButterfly, alt: 'Turf Green Butterfly', dataColor: 'TurfGreen'},
];

const Necks = [
  {src: BoldYellowNeck, alt: 'Bold Yellow Neck', dataColor: 'BoldYellow'},
  {src: AthleticGrayNeck, alt: 'Athletic Gray Neck', dataColor: 'AthleticGray'},
  {src: CleanWhiteNeck, alt: 'Clean White Neck', dataColor: 'CleanWhite'},
  {src: CrimsonRedNeck, alt: 'Crimson Red Neck', dataColor: 'CrimsonRed'},
  {src: NewNavyNeck, alt: 'New Navy Neck', dataColor: 'NewNavy'},
  {src: PerfectSeasonPurpleNeck, alt: 'Perfect Season Purple Neck', dataColor: 'PerfectSeasonPurple'},
  {src: PitchBlackNeck, alt: 'Pitch Black Neck', dataColor: 'PitchBlack'},
  {src: SunshineOrangeNeck, alt: 'Sunshine Orange Neck', dataColor: 'SunshineOrange'},
  {src: TrueBlueNeck, alt: 'True Blue Neck', dataColor: 'TrueBlue'},
  {src: TurfGreenNeck, alt: 'Turf Green Neck', dataColor: 'TurfGreen'}
];

const VerticalStrips = [
  {src: BoldYellowVerticalStrip, alt: 'Bold Yellow Vertical Strip', dataColor: 'BoldYellow'},
  {src: AthleticGrayVerticalStrip, alt: 'Athletic Gray Vertical Strip', dataColor: 'AthleticGray'},
  {src: CleanWhiteVerticalStrip, alt: 'Clean White Vertical Strip', dataColor: 'CleanWhite'},
  {src: CrimsonRedVerticalStrip, alt: 'Crimson Red Vertical Strip', dataColor: 'CrimsonRed'},
  {src: NewNavyVerticalStrip, alt: 'New Navy Vertical Strip', dataColor: 'NewNavy'},
  {src: PerfectSeasonPurpleVerticalStrip, alt: 'Perfect Season Purple Vertical Strip', dataColor: 'PerfectSeasonPurple'},
  {src: PitchBlackVerticalStrip, alt: 'Pitch Black Vertical Strip', dataColor: 'PitchBlack'},
  {src: SunshineOrangeVerticalStrip, alt: 'Sunshine Orange Vertical Strip', dataColor: 'SunshineOrange'},
  {src: TrueBlueVerticalStrip, alt: 'True Blue Vertical Strip', dataColor: 'TrueBlue'},
  {src: TurfGreenVerticalStrip, alt: 'Turf Green Vertical Strip', dataColor: 'TurfGreen'}
];

const ProofLabels = [
  {src: BoldYellowProofLabel, alt: 'Bold Yellow Proof Label', dataColor: 'BoldYellow'},
  {src: AthleticGrayProofLabel, alt: 'Athletic Gray Proof Label', dataColor: 'AthleticGray'},
  {src: CleanWhiteProofLabel, alt: 'Clean White Proof Label', dataColor: 'CleanWhite'},
  {src: CrimsonRedProofLabel, alt: 'Crimson Red Proof Label', dataColor: 'CrimsonRed'},
  {src: NewNavyProofLabel, alt: 'New Navy Proof Label', dataColor: 'NewNavy'},
  {src: PerfectSeasonPurpleProofLabel, alt: 'Perfect Season Purple Proof Label', dataColor: 'PerfectSeasonPurple'},
  {src: PitchBlackProofLabel, alt: 'Pitch Black Proof Label', dataColor: 'PitchBlack'},
  {src: SunshineOrangeProofLabel, alt: 'Sunshine Orange Proof Label', dataColor: 'SunshineOrange'},
  {src: TrueBlueProofLabel, alt: 'True Blue Proof Label', dataColor: 'TrueBlue'},
  {src: TurfGreenProofLabel, alt: 'Turf Green Proof Label', dataColor: 'TurfGreen'}
];

const cutButterflies = [
  {src: CleanWhiteCut, alt: 'Cut Clean White Butterfly', text: 'Clean White', dataColor: 'CleanWhite'},
  {src: PitchBlackCut, alt: 'Cut Pitch Black Butterfly', text: 'Pitch Black', dataColor: 'PitchBlack'},
  {src: AthleticGrayCut, alt: 'Cut Athletic Gray Butterfly', text: 'Athletic Gray', dataColor: 'AthleticGray'},
  {src: TurfGreenCut, alt: 'Cut Turf Green Butterfly', text: 'Turf Green', dataColor: 'TurfGreen'},
  {src: CrimsonRedCut, alt: 'Cut Crimson Red Butterfly', text: 'Crimson Red', dataColor: 'CrimsonRed'},
  {src: TrueBlueCut, alt: 'Cut True Blue Butterfly', text: 'True Blue', dataColor: 'TrueBlue'},
  {src: NewNavyCut, alt: 'Cut New Navy Butterfly', text: 'New Navy', dataColor: 'NewNavy'},
  {src: BoldYellowCut, alt: 'Cut Bold Yellow Butterfly', text: 'Bold Yellow', dataColor: 'BoldYellow'},
  {src: SunshineOrangeCut, alt: 'Cut Sunshine Orange Butterfly', text: 'Sunshine Orange', dataColor: 'SunshineOrange'},
  {src: PerfectSeasonPurpleCut, alt: 'Cut Perfect Season Purple Butterfly', text: 'Perfect Season Purple', dataColor: 'PerfectSeasonPurple'}
];


export default function Customizer({setCurrentComponent, setPageState, bottleState, setBottleState, setColorChoice, colorChoice}) {
  let previousLocation;

  const allOptionsSelected = Object.values(bottleState).every(value => value === true);

  const handleBtnClick = (e) => {
    //add class of active to button and remove active from all other buttons
    const buttons = document.querySelectorAll('.btn');
    buttons.forEach(button => {
      button.classList.remove('active');
    });
    //add active class to the button that was clicked
    e.currentTarget.classList.add('active');

    //get .image-wrapper
    const imageWrapper = document.querySelector('.image-wrapper');

    //get the data-img-location attribute from the button that was clicked
    const imgLocation = e.currentTarget.getAttribute('data-img-location');
    const zoomOut = document.querySelector('#zoom-out');
    if (imgLocation === 'Butterflies') {
      //empty classList
      imageWrapper.classList = 'image-wrapper active-butterfly customizer';
    } else if (imgLocation === 'Necks') {
      imageWrapper.classList = 'image-wrapper active-neck customizer';
    } else if (imgLocation === 'VerticalStrips') {
      imageWrapper.classList = 'image-wrapper active-vertical-strip customizer';
    } else if (imgLocation === 'ProofLabels') {
      imageWrapper.classList = 'image-wrapper active-proof-label customizer';
    } 
    zoomOut.classList = 'zoom-out active';
    if (imgLocation === previousLocation) {
      imageWrapper.classList = 'image-wrapper customizer';
      zoomOut.classList = 'zoom-out';
    }
    previousLocation = imgLocation;
    console.log(previousLocation)

  }
  const handleButterflyClick = (e) => {
    //get the data-color attribute from the butterfly that was clicked
    const color = e.currentTarget.getAttribute('data-color');
    //get data-img-location from the active .btn
    const imgLocation = document.querySelector('.btn.active').getAttribute('data-img-location');
    console.log(imgLocation);
    const imgArray = imgLocation === 'Butterflies' ? Butterflies : imgLocation === 'Necks' ? Necks : imgLocation === 'VerticalStrips' ? VerticalStrips : imgLocation === 'ProofLabels' ? ProofLabels : cutButterflies;
    console.log(imgArray);
    //get index where the data-color matches the color that was clicked
    const index = imgArray.findIndex(butterfly => butterfly.dataColor === color);
    //get the image src from the correct image variable because imgVariable is the name of the variable
    const imgSrc = imgArray[index].src;
    console.log(imgSrc);
    //change the src to the image that matches of class of imgLocation
    document.querySelector(`.${imgLocation}`).src = imgSrc; 

    if (imgLocation == 'Butterflies') {
      setBottleState({...bottleState, butterflies: true});
      setColorChoice({...colorChoice, butterflies: color})
    } else if (imgLocation == 'Necks') {
      setBottleState({...bottleState, necks: true});
      setColorChoice({...colorChoice, necks: color})
    } else if (imgLocation == 'VerticalStrips') {
      setBottleState({...bottleState, verticalStrips: true});
      setColorChoice({...colorChoice, verticalStrips: color})
    } else if (imgLocation == 'ProofLabels') {
      setBottleState({...bottleState, proofLabels: true});
      setColorChoice({...colorChoice, proofLabels: color})
    }
    console.log(colorChoice);

  }

  const handleZoom = (e) => {
    const imageWrapper = document.querySelector('.image-wrapper');
    imageWrapper.classList = 'image-wrapper customizer';
    console.log(imageWrapper)
    const zoomOut = document.querySelector('#zoom-out');
    zoomOut.classList = 'zoom-out';
  }

  const handleDone = () => {
    setPageState(8);
    let imageWrapper = document.querySelector('.image-wrapper');
    imageWrapper.classList = 'image-wrapper submit';
    let pageAnimation = document.getElementById('page-animation');
    pageAnimation.classList.remove('hide');
  }

  return (
    <div id='customizer'>

    {allOptionsSelected && (
      <button
        type='button'
        className="btn"
        onClick={() => handleDone()}
      >
        Done
      </button>
    )}
      {/* <button
          type='button'
          className="btn"
          onClick={() => setCurrentComponent('Form')}
      >
          Back
      </button> */}
      <div className='top'>
        <div id="zoom-out" onClick={(e) => handleZoom(e)}>Zoom Out</div>
        {/* <div className='image-wrapper'>
          <img className='bottle' src={Bottle} alt='bottle' />
          <img className='Butterflies' src={CleanWhiteButterfly} alt='Clean White Butterfly' />
          <img className='Necks' src={CleanWhiteNeck} alt='neck' />
          <img className='ProofLabels' src={CleanWhiteProofLabel} alt='proof-label' />
          <img className='VerticalStrips' src={CleanWhiteVerticalStrip} alt='vertical-strip' />
        </div> */}
      </div>
      <div className='bottom'>
        <div className='white-blur'></div>
        <div className='background'></div>
        <div className='btns-wrapper'>
          <div className='btn active' data-img-location="VerticalStrips" onClick={(e) => handleBtnClick(e)}>Strip Stamp</div>
          <div className='btn' data-img-location="Necks" onClick={(e) => handleBtnClick(e)}>Neck Label</div>
          <div className='btn' data-img-location="Butterflies" onClick={(e) => handleBtnClick(e)}>Butterfly</div>
          <div className='btn' data-img-location="ProofLabels" onClick={(e) => handleBtnClick(e)}>Proof Label</div>
        </div>
        <div className='butterflies-wrapper'>
          {cutButterflies.map((butterfly, index) => (
            <div className='butterfly' data-color={butterfly.dataColor} key={index} onClick={(e) => handleButterflyClick(e)}>
              <img src={butterfly.src} alt={butterfly.alt} />
              <h6>{butterfly.text}</h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
