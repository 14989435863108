import React, { useState } from "react";
import sha256 from "crypto-js/sha256";
import axios from "axios";

function LoginPage({isLoggedIn, setIsLoggedIn}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [maxAttempts] = useState(3);
  const [lockoutTime] = useState(30);
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  async function handleLogin({isLoggedIn, setIsLoggedIn}) {
    // Check if the user has reached the maximum number of attempts
    if (attempts >= maxAttempts) {
      setMessage(`You have exceeded the maximum number of login attempts. Please try again in ${lockoutTime} seconds.`);
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
        setAttempts(0);
        setMessage("");
      }, lockoutTime * 1000);
      return;
    }
  
    // Validate the username and password inputs to prevent XSS attacks
    if (!/^[\w]+$/i.test(username) || !/^[\S]+$/i.test(password)) {
        alert("Please enter a valid username and password.");
        return;
    }
    // Send the login request to the Firebase Cloud Function
    //https://us-central1-blue-run-spirits.cloudfunctions.net/app/login
    //http://127.0.0.1:5001/blue-run-spirits/us-central1/app/login
    axios.post("https://us-central1-blue-run-spirits.cloudfunctions.net/app/login", {
      username: username,
      password: password,
    })
      .then(async (response) => {
        // If the login is successful, redirect to the dashboard
        // and store the session token in local storage
        const token = response.data.token;
        await localStorage.setItem("token", token);
        //refresh
        await setMessage("Logging in...");
        await setIsLoggedIn(true);
        await console.log("isLoggedIn: " + isLoggedIn);
        window.location.reload();
      })
      .catch((error) => {
        // If the login fails, increment the login attempts counter
        // and display an error message
        setAttempts(attempts + 1);
        setMessage("Invalid username or password.");
        setIsLoggedIn(false);
      });
  }
  

  return (
    <div id="login">
      <h1>Login</h1>
        <form>
            <div className="page">
                <div className="row">
                    <div className="form-field">
                        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} disabled={disabled} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-field">
                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={disabled} />
                    </div>
                </div>
                <button className="btn" type="button" onClick={() => handleLogin({ isLoggedIn, setIsLoggedIn })} disabled={disabled}>
                    Login
                </button>
            </div>
        </form>
      <p>{message}</p>
    </div>
  );
}

export default LoginPage;
